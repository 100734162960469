/* eslint-disable indent */
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import {
  UserPermissionType,
  PresetReportDto,
  ReportType,
  ErrorRecordResponseModel,
  CarrierDetailsDto,
} from 'api/admin/api';
import { APIQueryKey } from 'api/react-query/helpers';
import {
  buildDataChannelTypeTextMapping,
  buildCommunicationMethodGroupTextMapping,
} from 'utils/i18n/enum-to-text';
import { AccessType } from 'types';
import {
  selectTimezones,
  selectActiveDomain,
  selectIsActiveDomainApciEnabled,
} from 'redux-app/modules/app/selectors';
import {
  enqueueSaveSuccessSnackbar,
  enqueueSnackbar,
} from 'redux-app/modules/app/actions';
import {
  selectUser,
  selectHasPermission,
  selectIsUserSystemAdmin,
} from 'redux-app/modules/user/selectors';

import { useQueryClient } from 'react-query';
import { Form, Formik } from 'formik';
import { generatePath, useHistory } from 'react-router-dom';
import routes from 'apps/reports/routes';
import PageIntroWrapper from 'components/layout/PageIntroWrapper';
import MessageBlock from 'components/MessageBlock';
import CircularProgress from 'components/CircularProgress';
import EditorBox from 'components/EditorBox';
import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRetrieveCurrentUserAccessibleDomains } from 'hooks/useRetrieveCurrentUserAccessibleDomains';
import PageIntro from '../../components/PageIntro';
import {
  EditorTextBox,
  EditorDropDown,
} from '../../components/common/EditorFields';
import { FilterOptions } from '../../components/types';
import useGetPresetReportInfo from '../../hooks/useGetPresetReportInfo';
import useGetDataChannelsAndInventory from '../../hooks/useGetDataChannelsAndInventory';
import useGetReportSchedules from '../../hooks/useGetReportShedules';
import useSaveReportInfo from '../../hooks/useSaveReportInfo';
import useDeleteSchedules from '../../hooks/useDeleteSchedules';
import SchedulesTable from '../../components/SchedulesTable';
import FormMissingReadingBatteryVoltage from '../../components/FormMissingReadingBatteryVoltage';
import FormUserRoleListEventRule from '../../components/FormUserRoleListEventRule';
import FormMissingData from '../../components/FormMissingData';
import FormAssetDataChannelSiteList from '../../components/FormAssetDataChannelSiteList';
import FormTankDimensionsList from '../../components/FormTankDimensionsList';
import FormProductList from '../../components/FormProductList';
import FormDomainDCTemplateList from '../../components/FormDomainDCTemplateList';
import FormUserList from '../../components/FormUserList';
import FormAssetSummary from '../../components/FormAssetSummary';
import FormNewAsset from '../../components/FormNewAsset';
import FormCustomCSV from '../../components/FormCustomCSV';
import FormProcedure from '../../components/FormProcedure';
import FormHornerDOE from '../../components/FormHornerDOE';
import FormHornerDetail from '../../components/FormHornerDetail';
import AddScheduleDrawer from '../../components/AddScheduleDrawer';

type ReportEditorProps = {
  reportId: string;
};

const ReportsEditor = ({ reportId }: ReportEditorProps) => {
  const { t } = useTranslation();

  const languageList = [
    {
      label: t('enum.displaylanguage.en.us', 'English'),
      englishLabel: 'en_US',
    },
    {
      label: t('enum.displaylanguage.de', 'German'),
      englishLabel: 'de',
    },
    {
      label: t('enum.displaylanguage.es', 'Spanish'),
      englishLabel: 'es',
    },
    {
      label: t('enum.displaylanguage.fr.ca', 'French'),
      englishLabel: 'fr_CA',
    },
    {
      label: t('enum.displaylanguage.th', 'Thai'),
      englishLabel: 'th',
    },
    {
      label: t('enum.displaylanguage.zh.hans', 'Chinese (Simplified)'),
      englishLabel: 'zh_Hans',
    },
    {
      label: t('enum.displaylanguage.zh.hant', 'Chinese (Traditional)'),
      englishLabel: 'zh_Hant',
    },
    {
      label: t('enum.displaylanguage.vi.vn', 'Vietnamese'),
      englishLabel: 'vi_VN',
    },
  ];

  const history = useHistory();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const timezonesData = useSelector(selectTimezones);
  const activeDomain = useSelector(selectActiveDomain);
  const user = useSelector(selectUser);
  const hasPermission = useSelector(selectHasPermission);
  const isSystemAdmin = useSelector(selectIsUserSystemAdmin);
  const isAirProductsEnabledDomain = useSelector(
    selectIsActiveDomainApciEnabled
  );
  const dataChannelTypeTextMapping = buildDataChannelTypeTextMapping(t);
  const carriersEnum = buildCommunicationMethodGroupTextMapping(t);

  const canUpdateReport = hasPermission(
    UserPermissionType.ReportAccess,
    AccessType.Update
  );

  const isReportEditable = useMemo(
    () => (isSystemAdmin || !isAirProductsEnabledDomain) && canUpdateReport,
    [canUpdateReport, isAirProductsEnabledDomain, canUpdateReport]
  );

  const userId =
    user.data?.authenticateAndRetrieveApplicationInfoResult?.userInfo?.userId ||
    '';
  const domainId = activeDomain?.domainId || '';
  const [isAddScheduleDrawerOpen, setIsAddScheduleDrawerOpen] = useState(false);
  const [dataChannelsOptions, setDataChannelsOptions] = useState<string[]>([]);
  const [inventoryStatesOptions, setInventoryStatesOptions] = useState<
    string[]
  >([]);
  const [selectedDomain, setSelectedDomain] = useState<
    string | null | undefined
  >(null);

  const retrieveDataChannelsAndInventoryStates = useGetDataChannelsAndInventory(
    domainId,
    userId,
    selectedDomain
  );

  const {
    dataChannels,
    inventoryStates,
    assetGroups,
    templatesReport,
    carriers,
  } = retrieveDataChannelsAndInventoryStates.data;

  const [dataCarriersOptions, setDataCarriersOptions] = useState<string[]>([]);

  const retrieveCurrentUserAccessibleDomainsApi = useRetrieveCurrentUserAccessibleDomains(
    true
  );

  useEffect(() => {
    if (dataChannels && dataChannels.result) {
      const arrayNames = dataChannels.result.map(
        (dataEnum) => dataChannelTypeTextMapping[dataEnum]
      );
      setDataChannelsOptions(arrayNames);
    }
    if (inventoryStates && inventoryStates.result) {
      setInventoryStatesOptions(inventoryStates.result);
    }

    if (carriers) {
      setDataCarriersOptions(
        carriers.map((carr: CarrierDetailsDto) => carr.name as string)
      );
    }
  }, [dataChannels, inventoryStates, carriers]);

  const assetGroupOptions: { [key: string]: string } = useMemo(() => {
    const optionsAssGroup: { [key: string]: string } = {};
    if (assetGroups) {
      assetGroups.map((ass) => {
        if (ass.id && ass.name) {
          optionsAssGroup[ass.id] = ass.name;
        }
        return null;
      });
    }
    return optionsAssGroup;
  }, [assetGroups]);

  const templatesOptions: { [key: string]: string } = useMemo(() => {
    const optionsTemplatesReports: { [key: string]: string } = {};
    if (templatesReport) {
      templatesReport.map((ass) => {
        if (ass.presetReportTemplateId && ass.name) {
          optionsTemplatesReports[ass.presetReportTemplateId] = ass.name;
        }
        return null;
      });
    }
    return optionsTemplatesReports;
  }, [templatesReport]);

  const accessibleDomains = retrieveCurrentUserAccessibleDomainsApi.data || [];

  const filteredTimeZonesData = timezonesData.timezones.filter(
    (timezone) => timezone.timezoneId !== -1
  );

  const optionsTimeZone: { [key: number]: string } = {};
  const sortedTimeZoneArray: {
    key: string | number;
    value: string | number;
  }[] = [];
  filteredTimeZonesData
    .sort((a, b) => a.utcOffsetInHours! - b.utcOffsetInHours!)
    .map((tzone) => {
      if (tzone.timezoneId && tzone.displayName) {
        optionsTimeZone[tzone.timezoneId] = tzone.displayName;
        sortedTimeZoneArray.push({
          key: tzone.timezoneId,
          value: tzone.displayName,
        });
      }
      return null;
    });

  const optionsLanguage: { [key: string]: string } = {};
  languageList.map((lan) => {
    if (lan.label && lan.englishLabel) {
      optionsLanguage[lan.englishLabel] = lan.label;
    }
    return null;
  });

  const { data, isFetching } = useGetPresetReportInfo(Number(reportId));
  const {
    data: dataSchedules,
    isFetching: isFetchingSchedules,
  } = useGetReportSchedules(Number(reportId));

  const optionsDomains = useMemo(() => {
    const addAllOptionDomain = !templatesReport?.find(
      (tmp) => tmp.presetReportTemplateId === data?.reportType
    )?.isStoredProcedure;

    const optionsDomainsArray: { [key: string]: string } = addAllOptionDomain
      ? {
          '00000000-0000-0000-0000-000000000000': 'All',
        }
      : {};
    accessibleDomains.map((dom) => {
      if ((dom.displayName || dom.name) && dom.domainId) {
        optionsDomainsArray[dom.domainId] = (dom.displayName ||
          dom.name) as string;
      }
      return null;
    });

    return optionsDomainsArray;
  }, [data?.reportType, accessibleDomains, templatesReport]);

  const filterByOptionsOne = () => ({
    [FilterOptions.AllFields]: t('enum.common.allfields', 'All Fields'),
    [FilterOptions.AssetTitle]: t('ui.datachannel.assettitle', 'Asset Title'),
    [FilterOptions.Site]: t('ui.datachannel.assetlocation', 'Asset Location'),
    [FilterOptions.Description]: t('ui.common.description', 'Description'),
    [FilterOptions.CustomerName]: t('ui.common.customername', 'Customer Name'),
    [FilterOptions.Domain]: t('ui.common.domain', 'Domain'),
    [FilterOptions.RTU]: t('ui.common.rtu', 'RTU'),
    [FilterOptions.Type]: t('ui.common.type', 'Type'),
  });

  const inventorySelected = useMemo(
    () =>
      data?.includedInventoryStates && data?.includedInventoryStates.length > 0
        ? data?.includedInventoryStates?.split(',')
        : [],
    [data]
  );

  const [selectedInventoryLevels, setSelectedInventoryLevels] = useState<
    string[]
  >(inventorySelected || []);

  const dataChannelSelected = useMemo(
    () =>
      data?.includedDataChannelTypes &&
      data?.includedDataChannelTypes.length > 0
        ? data?.includedDataChannelTypes
            ?.split(',')
            .map(
              (dataEnum) =>
                dataChannelTypeTextMapping[
                  (dataEnum as unknown) as keyof typeof dataChannelTypeTextMapping
                ]
            )
        : [],
    [data]
  );
  const [selectedDataChannels, setSelectedDataChannels] = useState<string[]>(
    dataChannelSelected || []
  );

  const dataCarriersSelected = useMemo(
    () =>
      data?.includedCarriers && data?.includedCarriers.length > 0
        ? data?.includedCarriers
            ?.split(',')
            .map((carr) => carriersEnum[Number(carr)])
        : [],
    [data]
  );

  const [selectedCarriers, setSelectedCarriers] = useState<string[]>(
    dataCarriersSelected || []
  );

  useEffect(() => {
    if (dataCarriersSelected && dataCarriersSelected.length > 0) {
      setSelectedCarriers(dataCarriersSelected);
    }
  }, [dataCarriersSelected]);

  const initialStartDate = moment();
  const [localStartDate, setLocalStartDate] = useState<moment.Moment | null>(
    data?.timePeriodStartDate
      ? moment.utc(data?.timePeriodStartDate)
      : initialStartDate
  );
  const [localEndDate, setLocalEndDate] = useState<moment.Moment | null>(
    data?.timePeriodEndDate
      ? moment.utc(data?.timePeriodEndDate)
      : initialStartDate
  );
  const [valuesParams, setValuesParams] = useState(
    data?.presetReportParameters || []
  );
  useEffect(() => {
    if (data) {
      setSelectedInventoryLevels(inventorySelected || []);
      setSelectedDataChannels(dataChannelSelected || []);
      setValuesParams(data?.presetReportParameters || []);
      setSelectedDomain(data?.selectedDomainId || null);
      setLocalStartDate(
        data?.timePeriodStartDate
          ? moment.utc(data?.timePeriodStartDate)
          : initialStartDate
      );
      setLocalEndDate(
        data?.timePeriodEndDate
          ? moment.utc(data?.timePeriodEndDate)
          : initialStartDate
      );
    }
  }, [data]);

  const saveReportInfo = useSaveReportInfo();

  const cancelCallback = () => {
    saveReportInfo.reset();
    history.push(generatePath(routes.reports.list));
  };

  const saveAndExitCallback = () => {};

  const [formErrors, setFormErrors] = useState<{
    [key: string]: string;
  }>({});

  const handleErrorsOnSave = (errors: ErrorRecordResponseModel[]) => {
    const errorsMap: { [key: string]: string } = {};

    for (let i = 0; i < errors?.length; i++) {
      if (errors?.[i] && errors?.[i]?.propertyName) {
        const newPropertyName = errors?.[i]?.propertyName || '';
        errorsMap[newPropertyName] = errors?.[i]?.errorMessage || '';
      }
    }

    setFormErrors(errorsMap);
  };

  const selectForm = (reportValue: ReportType) => {
    switch (Number(reportValue)) {
      case ReportType.AssetSummaryList:
        return 'AssetSummaryList';
      case ReportType.DataChannelsWithMissingReadings:
      case ReportType.LowBatteryVoltage:
        return 'MissingReadings&BatteryVoltage';
      case ReportType.NewAssetList:
        return 'NewAssetList';
      case ReportType.MissingData:
        return 'MissingData';
      case ReportType.AssetList:
      case ReportType.DataChannelList:
      case ReportType.SiteList:
      case ReportType.RTUList:
        return 'Asset&DC&SiteList';
      case ReportType.TankDimensionList:
        return 'TankDimensionList';
      case ReportType.ProductList:
      case ReportType.AssetGroupList:
        return 'Product&AssetGroupList';
      case ReportType.DomainList:
      case ReportType.DataChannelTemplateList:
        return 'Domain&DCTemplateList';
      case ReportType.UserList:
        return 'UserList';
      case ReportType.UserRoleList:
      case ReportType.EventRuleGroupList:
        return 'UserRole&EventGroupList';
      case ReportType.CustomCSVReport:
        return 'CustomCSVReport';
      case ReportType.HornerDOEReport:
        return 'HornerDOEReport';
      case ReportType.HornerDetailReport:
        return 'HornerDetailReport';
      default:
        return 'StoredProcedureReport';
    }
  };

  const deleteSchedule = useDeleteSchedules(Number(reportId));

  const handleDeleteOne = async (scheduleId: number) => {
    try {
      await deleteSchedule.mutateAsync(scheduleId);
      queryClient.invalidateQueries([
        APIQueryKey.retrieveReportSchedules,
        Number(reportId),
      ]);
      dispatch(
        enqueueSnackbar({
          message: t(
            'ui.report.schedulesuccessfullydeleted',
            'Schedule successfully deleted.'
          ),
          options: { variant: 'success' },
        })
      );
    } catch (e) {
      dispatch(
        enqueueSnackbar({
          message: t('ui.common.defaultError', 'An unexpected error occurred'),
          options: { variant: 'error' },
        })
      );
    }
  };

  const handleDeleteSchedule = (scheduleTypeArray: string[]) => {
    scheduleTypeArray.map((schedule) => handleDeleteOne(Number(schedule)));
  };

  const getIdsCarriers = (selectedCarriersArray: string[]) => {
    if (carriers) {
      const IdsCarriers = selectedCarriersArray.map(
        (selCarr) => carriers.find((opCarr) => opCarr.name === selCarr)?.id
      );
      return IdsCarriers;
    }
    return [];
  };

  const canAddSchedule = useMemo(
    () =>
      !!canUpdateReport &&
      data?.reportType !== ReportType.CustomCSVReport &&
      data?.reportType !== ReportType.DomainList &&
      data?.reportType !== ReportType.TankDimensionList,
    [data?.reportType, canUpdateReport]
  );

  return (
    <>
      <AddScheduleDrawer
        reportId={Number(reportId)}
        reportName={data?.name || ''}
        userEmailList={data?.userEmailList}
        isOpen={isAddScheduleDrawerOpen}
        setIsOpen={setIsAddScheduleDrawerOpen}
        onClose={() => setIsAddScheduleDrawerOpen(false)}
      />
      <Fade in={isFetching} unmountOnExit>
        <div>
          {isFetching && (
            <MessageBlock>
              <CircularProgress />
            </MessageBlock>
          )}
        </div>
      </Fade>
      <Fade in={!isFetching && !isFetchingSchedules}>
        <div>
          {!isFetching && (
            <Formik<PresetReportDto>
              initialValues={data!}
              onSubmit={(values: PresetReportDto, actions) => {
                saveReportInfo.reset();

                saveReportInfo.mutate(
                  ({
                    ...values,
                    includedInventoryStates: selectedInventoryLevels.join(','),
                    includedDataChannelTypes: selectedDataChannels
                      .map((dataName) =>
                        Object.keys(dataChannelTypeTextMapping).find(
                          // @ts-ignore
                          (k) => dataChannelTypeTextMapping[k] === dataName
                        )
                      )
                      .join(','),
                    includedCarriers: getIdsCarriers(selectedCarriers).join(
                      ','
                    ),
                    timePeriodStartDate: moment(localStartDate).format(
                      'YYYY-MM-DD'
                    ),
                    timePeriodEndDate: moment(localEndDate).format(
                      'YYYY-MM-DD'
                    ),
                    presetReportParameters: valuesParams,
                  } as unknown) as PresetReportDto,
                  {
                    onSuccess: () => {
                      setFormErrors({});
                      dispatch(enqueueSaveSuccessSnackbar(t));
                      saveReportInfo.reset();
                      history.push(generatePath(routes.reports.list));
                    },
                    onError: (errors: any) => {
                      handleErrorsOnSave(errors);
                      actions.setSubmitting(false);
                    },
                    onSettled: () => {
                      actions.setSubmitting(false);
                    },
                  }
                );
              }}
            >
              {({
                isSubmitting,
                submitForm,
                values,
                setValues,
                setFieldValue,
              }) => {
                return (
                  <Form>
                    <PageIntroWrapper>
                      <PageIntro
                        isCreating={!reportId}
                        isSubmitting={isSubmitting}
                        submissionResult={saveReportInfo.data}
                        submissionError={saveReportInfo.error}
                        cancelCallback={cancelCallback}
                        saveAndExitCallback={saveAndExitCallback}
                        submitForm={submitForm}
                        isReadOnly={!isReportEditable}
                      />
                    </PageIntroWrapper>
                    <EditorBox p={4} mb={3}>
                      <Grid container spacing={4} alignItems="center">
                        <Grid item xs={4}>
                          <EditorDropDown
                            label={`${t(
                              'ui.reports.reporttype',
                              'Report Type'
                            )}*`}
                            name="reportType"
                            disabled
                            onChange={() => setValuesParams([])}
                            textMapping={templatesOptions}
                            error={!!formErrors.ReportType}
                            errorMessage={formErrors?.ReportType}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <EditorTextBox
                            label={`${t('ui.reports.name', 'Name')}*`}
                            name="name"
                            error={!!formErrors.Name}
                            errorMessage={formErrors?.Name}
                          />
                        </Grid>
                      </Grid>
                    </EditorBox>

                    <EditorBox p={4} mb={3}>
                      {selectForm(values.reportType!) ===
                        'AssetSummaryList' && (
                        <FormAssetSummary
                          data={data}
                          values={values}
                          setValues={setValues}
                          optionsLanguage={optionsLanguage}
                          optionsTimeZone={optionsTimeZone}
                          sortedTimeZoneArray={sortedTimeZoneArray}
                          assetGroupOptions={assetGroupOptions}
                          dataChannelsOptions={
                            (dataChannelsOptions as unknown) as string[]
                          }
                          inventoryStatesOptions={inventoryStatesOptions}
                          filterOptions1={filterByOptionsOne()}
                          selectedInventoryLevels={selectedInventoryLevels}
                          setSelectedInventoryLevels={
                            setSelectedInventoryLevels
                          }
                          selectedDataChannels={selectedDataChannels}
                          setSelectedDataChannels={setSelectedDataChannels}
                        />
                      )}
                      {selectForm(values.reportType!) === 'NewAssetList' && (
                        <FormNewAsset
                          data={data}
                          values={values}
                          optionsLanguage={optionsLanguage}
                          optionsTimeZone={optionsTimeZone}
                          sortedTimeZoneArray={sortedTimeZoneArray}
                          assetGroupOptions={assetGroupOptions}
                          dataChannelsOptions={
                            (dataChannelsOptions as unknown) as string[]
                          }
                          selectedDataChannels={selectedDataChannels}
                          setSelectedDataChannels={setSelectedDataChannels}
                          localStartDate={localStartDate}
                          setLocalStartDate={setLocalStartDate}
                          localEndDate={localEndDate}
                          setLocalEndDate={setLocalEndDate}
                        />
                      )}
                      {selectForm(values.reportType!) ===
                        'MissingReadings&BatteryVoltage' && (
                        <FormMissingReadingBatteryVoltage
                          data={data}
                          optionsLanguage={optionsLanguage}
                          optionsTimeZone={optionsTimeZone}
                          sortedTimeZoneArray={sortedTimeZoneArray}
                          assetGroupOptions={assetGroupOptions}
                          values={values}
                          dataChannelsOptions={
                            (dataChannelsOptions as unknown) as string[]
                          }
                          selectedDataChannels={selectedDataChannels}
                          setSelectedDataChannels={setSelectedDataChannels}
                        />
                      )}
                      {selectForm(values.reportType!) === 'MissingData' && (
                        <FormMissingData
                          data={data}
                          setValue={setFieldValue}
                          setSelectedDomain={setSelectedDomain}
                          selectedDomain={selectedDomain}
                          optionsLanguage={optionsLanguage}
                          optionsTimeZone={optionsTimeZone}
                          sortedTimeZoneArray={sortedTimeZoneArray}
                          optionsDomains={optionsDomains}
                          localStartDate={localStartDate}
                          setLocalStartDate={setLocalStartDate}
                          localEndDate={localEndDate}
                          setLocalEndDate={setLocalEndDate}
                          dataCarriersOptions={dataCarriersOptions}
                          selectedCarriers={selectedCarriers}
                          setSelectedCarriers={setSelectedCarriers}
                        />
                      )}
                      {selectForm(values.reportType!) ===
                        'Asset&DC&SiteList' && (
                        <FormAssetDataChannelSiteList
                          data={data}
                          values={values}
                          reportType={data?.reportType!}
                          optionsLanguage={optionsLanguage}
                          optionsTimeZone={optionsTimeZone}
                          sortedTimeZoneArray={sortedTimeZoneArray}
                          filterOptions1={filterByOptionsOne()}
                        />
                      )}
                      {selectForm(values.reportType!) ===
                        'TankDimensionList' && (
                        <FormTankDimensionsList
                          data={data}
                          optionsLanguage={optionsLanguage}
                          optionsTimeZone={optionsTimeZone}
                          sortedTimeZoneArray={sortedTimeZoneArray}
                        />
                      )}
                      {selectForm(values.reportType!) ===
                        'Product&AssetGroupList' && (
                        <FormProductList
                          data={data}
                          optionsLanguage={optionsLanguage}
                          optionsTimeZone={optionsTimeZone}
                          sortedTimeZoneArray={sortedTimeZoneArray}
                        />
                      )}
                      {selectForm(values.reportType!) ===
                        'Domain&DCTemplateList' && (
                        <FormDomainDCTemplateList
                          data={data}
                          reportType={data?.reportType!}
                          optionsLanguage={optionsLanguage}
                          optionsTimeZone={optionsTimeZone}
                          sortedTimeZoneArray={sortedTimeZoneArray}
                          values={values}
                        />
                      )}

                      {selectForm(values.reportType!) ===
                        'UserRole&EventGroupList' && (
                        <FormUserRoleListEventRule
                          data={data}
                          optionsLanguage={optionsLanguage}
                          optionsTimeZone={optionsTimeZone}
                          sortedTimeZoneArray={sortedTimeZoneArray}
                        />
                      )}
                      {selectForm(values.reportType!) === 'UserList' && (
                        <FormUserList
                          data={data}
                          optionsLanguage={optionsLanguage}
                          optionsTimeZone={optionsTimeZone}
                          sortedTimeZoneArray={sortedTimeZoneArray}
                          values={values}
                          setValues={setValues}
                        />
                      )}
                      {selectForm(values.reportType!) === 'CustomCSVReport' && (
                        <FormCustomCSV
                          data={data}
                          values={values}
                          optionsLanguage={optionsLanguage}
                          optionsTimeZone={optionsTimeZone}
                          sortedTimeZoneArray={sortedTimeZoneArray}
                          assetGroupOptions={assetGroupOptions}
                          dataChannelsOptions={
                            (dataChannelsOptions as unknown) as string[]
                          }
                          filterOptions1={filterByOptionsOne()}
                          selectedDataChannels={selectedDataChannels}
                          setSelectedDataChannels={setSelectedDataChannels}
                        />
                      )}
                      {selectForm(values.reportType!) === 'HornerDOEReport' && (
                        <FormHornerDOE
                          values={values}
                          optionsLanguage={optionsLanguage}
                          optionsTimeZone={optionsTimeZone}
                          sortedTimeZoneArray={sortedTimeZoneArray}
                          localStartDate={localStartDate}
                          setLocalStartDate={setLocalStartDate}
                          localEndDate={localEndDate}
                          setLocalEndDate={setLocalEndDate}
                        />
                      )}

                      {selectForm(values.reportType!) ===
                        'HornerDetailReport' && (
                        <FormHornerDetail
                          data={data}
                          values={values}
                          setFieldValue={setFieldValue}
                          optionsTimeZone={optionsTimeZone}
                          sortedTimeZoneArray={sortedTimeZoneArray}
                          optionsDomains={optionsDomains}
                          templatesReport={templatesReport || []}
                          valuesParams={valuesParams}
                          setValuesParams={setValuesParams}
                        />
                      )}

                      {selectForm(values.reportType!) ===
                        'StoredProcedureReport' && (
                        <FormProcedure
                          data={data}
                          values={values}
                          setFieldValue={setFieldValue}
                          optionsTimeZone={optionsTimeZone}
                          sortedTimeZoneArray={sortedTimeZoneArray}
                          optionsDomains={optionsDomains}
                          templatesReport={templatesReport || []}
                          valuesParams={valuesParams}
                          setValuesParams={setValuesParams}
                        />
                      )}
                    </EditorBox>
                  </Form>
                );
              }}
            </Formik>
          )}
          {!isFetchingSchedules && (
            <SchedulesTable
              isSuccessDeletion={!!deleteSchedule.isSuccess}
              isLoadingDeletion={!!deleteSchedule.isLoading}
              data={dataSchedules || []}
              canUpdateReport={!!canUpdateReport}
              canAddSchedule={!!canAddSchedule}
              isFetching={isFetchingSchedules}
              error={false}
              handleDeleteSchedule={handleDeleteSchedule}
              handleAddSchedule={() => setIsAddScheduleDrawerOpen(true)}
            />
          )}
        </div>
      </Fade>
    </>
  );
};

export default ReportsEditor;
